import React from "react";
import { createStyles, Stack, Container, Text, useMantineColorScheme, Skeleton, Center, Group, Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { PlayerPlay } from "tabler-icons-react";
import { getLang, substringBetween } from "../../utils/helpers";
import "../../css/steps.css";

const useStyles = createStyles((theme) => ({
	step: {
		width: "90%",
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#F9DEB2"
	},
	stepFocussed: {
		width: "90%",
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#F9DEB2",
		animationName: "pulse", animationFillMode: "both", animationDuration: "1s"
	}
}));

export function Steps({ dict, currentStatus, answer, setStepTapped }) {
	const { classes } = useStyles();
	const { colorScheme } = useMantineColorScheme();

	const gameEnded = currentStatus?.win || currentStatus?.loose;

	const stepTapped = (step) => {
		if (step <= currentStatus?.step || gameEnded)
			setStepTapped(step);
		else
			showNotification({ message: dict.shouldSkip, sx: { bottom: 90 } });
	};
	const translateInstruments = (s) => {
		if (s.indexOf("[") === -1) return dict[s];
		while (s.indexOf("[") !== -1) {
			const instrument = substringBetween(s, "[", "]");
			s = s.replace(`[${instrument}]`, dict[instrument] || instrument);
		}
		return s;
	};

	const getColor = (i) => {
		return i < currentStatus?.step
			? (currentStatus?.guesses[i] === "y"
				? "#F4831B"
				: (answer.skin === "halloween" ? "#e675ff" : "red"))
			: (i === currentStatus?.step
				? (colorScheme === "dark" ? "#63C328" : "#2e8a2b")
				: (colorScheme === "dark" ? "white" : "black"));
	};
	const getLabel = i => {
		return i < answer.instruments.length || (currentStatus?.step < answer.instruments.length && !gameEnded)
			? translateInstruments(answer?.instruments[i - 1])
			: answer?.clue[getLang()];
	};

	const loadingContent = [1, 2, 3, 4, 5, 6].map(i =>
		<Center key={i}><Skeleton mt={4} mb={6} height={32} sx={{ width: "90%" }} /></Center>);

	const loadedContent = answer?.instruments?.map((x, i) => (
		<Container key={i} mb={7} pt={2} pb={2} className={i + 1 === currentStatus?.step ? classes.stepFocussed : classes.step}
			onClick={() => stepTapped(i + 1)}>
			<Group position="apart" spacing={0}>
				<Text size="lg" color={getColor(i + 1)}>{i + 1} - {getLabel(i + 1)}</Text>
				{gameEnded && i + 1 < answer.instruments.length &&
					<Button sx={theme => ({ color: theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[9], borderColor: theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[9] })}
						variant="outline" radius="xl" size="xs" compact>
						<PlayerPlay size={16} />
					</Button>
				}
			</Group>
		</Container>
	));

	return (
		<Stack spacing={0}>
			{answer?.instruments ? loadedContent : loadingContent}
		</Stack>
	);
}